import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
class Footer extends React.Component
{
  render()
  {
    return (
       <footer class="pt-4 pt-md-5 border-top">
     
  <div class="container">
      <div class="row">
         <div class="col-6 col-md">
              <h5>Features</h5>
   <ul class="list-unstyled text-small">
                  <li><a class="text-muted" href="#">Privacy Policy</a></li>
                  <li><a class="text-muted" href="#">Terms and Condtions</a></li>
              </ul>
        
        
          </div>
          <div class="col-6 col-md">
              <h5>Resources</h5>
             <ul class="list-unstyled text-small">
                  <li><a class="text-muted" href="#">Privacy Policy</a></li>
                  <li><a class="text-muted" href="#">Terms and Condtions</a></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h5>About</h5>
              <ul class="list-unstyled text-small">
                  <li><a class="text-muted" href="#">About Us</a></li>
                  <li><a class="text-muted" href="#">Contact Us</a></li>
              </ul>
          </div>
      </div>
  </div>       
  <div class="text-center py-4 bg-light mt-4">Copyright 2023 | All right reserved Invoice Free Generator</div>
</footer>
    )
  }
}
export default Footer;
